import React from 'react';

import './modal.css';

type Props = {
    onClose: Function;
    onLogout: Function;
    show: boolean;
}

function Modal(props: any) {

    if(!props.show) {
        return null;
      }

    return (
        <div className="backdrop">
          <div className="modal">
            <div className='modal-content'>
                <h1>Oh no! Something went wrong!</h1>
                <hr></hr>
                <p>Looks like you have been disconnected! Please check your internet connection and either re-login or refresh session</p>
            </div>
            <div className="modal-footer">
              <button onClick={props.onLogout}>
                Logout
              </button>
              <button onClick={props.onRefreshSession}>
                Refresh Session
              </button>
            </div>
          </div>
        </div>
      );
}

export default Modal;