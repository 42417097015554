export class PhoneNumber
{
  private phoneNumber: string;

    constructor(phoneNumber: string)
    {
      this.phoneNumber = this.getInternationalFormat(phoneNumber);
    }

    //converts a phone number to the format that is required by Cognito
    private getInternationalFormat(phoneNumber: string) : string
    {
      if (phoneNumber.startsWith("0"))
      {
        phoneNumber = "+27" + phoneNumber.substring(1);
      }

      // add + to phone number if it doesn't exist
      if (!phoneNumber.startsWith("+"))
      {
        phoneNumber = "+" + phoneNumber;
      }

      return phoneNumber;
    }
    //converts a phone number to the format that is required by the Bot
    getInternationalFormatWithoutPlus()
    {
      this.phoneNumber = this.phoneNumber.substring(1);
      return this.phoneNumber;
    }
    //converts a phone number to the format that is used locally
    getLocalFormat()
    {
      this.phoneNumber = "0" + this.phoneNumber.substring(3);
      return this.phoneNumber;
    }

    toString() {
      return this.phoneNumber;
    }
}