import axios from 'axios';
import { CompanyConfigSchema } from '../models/Schemas';

const env = process.env.NODE_ENV === 'production' ? 'prod': 'stage';
const dynamoDomain = `https://hapi.xiva-react-chatbot-af.${env}.xibsolutions.com`;


export async function queryCompanyConfig(companyName: string) {
    try {
        const response = await axios.get(`${dynamoDomain}/dynamo/company-config?companyName=${companyName}`);
        const validationResults = CompanyConfigSchema.validate(response.data);
        if (validationResults.error) {
          console.log(validationResults)
          throw validationResults.error;
        }
        return response.data;
      } catch (error) {
        console.error(`Error fetching company config for ${companyName}:`, error);
        if (axios.isAxiosError(error)) {
          error = new Error(
            `Message: ${JSON.stringify(error.message)}\n` + `Code: ${JSON.stringify(error.code)}\n` + `Connection: ${JSON.stringify(error.config.url)}\n`
          )
        }
        throw error;
      }
} 