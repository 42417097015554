import React, {useState} from 'react';
import './otpForm.css';

type Props = {
	formSubmit: Function;
  };


function OtpForm(props: Props) {
	const [otp, setOpt] = useState<string>('');
	const [hasOtpError, setHasOtpError] = useState<boolean>(false);

	const [hasFormError, setHasFormError] = useState<boolean>(false);

	//validation for basic input
	const validateInput = (value: string) => {
		if (value.trim() === ''){
			return false;
		  }
		  return true;
	}

	//validate form
	const validateForm = () => {
				//validate that no errors on inputs
				if(hasOtpError) {
					return false;
				}				
				// validate that inputs have values
				if(!(otp.length > 0)) {
					return false;
				}
				return true
	}

	//dynamic function to update state for forms items by name
	const handleFormUpdate = (event: any) => {
				setOpt(event.target.value);
				const otpValid = validateInput(event.target.value);
				if (!otpValid) {
					setHasOtpError(true);
				}else {
					setHasOtpError(false);
				}

				if(!hasOtpError) {
					setHasFormError(false);
				}
	}

	const handleFormSubmit = (event: any) => {
		event.preventDefault();
		
		const formValid = validateForm();
		if(!formValid) {
			setHasFormError(true);
			return;
		}
		setHasFormError(false);
		props.formSubmit({otp: otp});
	}

  return (
    <React.Fragment>
	<div className="screen">
		<div className="screen__content">
			<form className="otp" onSubmit={handleFormSubmit}>
                <div className="otp__field">
					<i className="otp__icon fas fa-user"></i>
					<input type="text" name="opt_number" className="otp__input" onChange={handleFormUpdate} value={otp} placeholder="Please enter your OTP" />
					{hasOtpError && <p className="otp__error">Please enter a valid OPT Number.</p>}
				</div>
				<button className="button otp__submit">
					<span className="button__text">Verify</span>
					<i className="button__icon fas fa-chevron-right"></i>
				</button>
				{hasFormError && <p className="otp__error">Sorry, Please make sure the form is filled out correctly.</p>}				
			</form>
		</div>
		<div className="screen__background">
			<span className="screen__background__shape screen__background__shape4"></span>
			<span className="screen__background__shape screen__background__shape3"></span>		
			<span className="screen__background__shape screen__background__shape2"></span>
			<span className="screen__background__shape screen__background__shape1"></span>
		</div>		
	</div>
</React.Fragment>
  );
}

export default OtpForm;
