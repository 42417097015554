import React from 'react';
import Home from './components/home/home';
import {Auth} from './components/Auth/Auth';
import './App.css';
import { ConfigProvider } from './components/ConfigContext/Config';
import ErrorBoundary from './components/utility/ErrorBoundary';

function App() {


  return (
    <div className="App">
      <ErrorBoundary>
        <ConfigProvider>
          <Auth>
            <Home />
          </Auth>
        </ConfigProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
