import React, { useState, useEffect, useContext } from 'react';
import configContext from '../ConfigContext/Config';
import './modal.css';
import moment from 'moment';

type Props = {
  onClose: Function;
  onLogout: Function;
  show: boolean;
}

function TimeoutModal(props: any) {
  const { properties } = useContext(configContext);
  const [countdown, setCountdown] = useState(properties.sessionTimeout.sessionExpiryWarningTimeout ? moment.duration(properties.sessionTimeout.sessionExpiryWarningTimeout).asMilliseconds() / 1000 : 1 * 60 * 1000);

  useEffect(() => {
    if (props.show && countdown > 0) {
      const timer = setTimeout(() => setCountdown(countdown - 1), 1000);
      return () => clearTimeout(timer);
    }

    if (countdown === 0) {
      props.onLogout();
      setCountdown(properties.sessionTimeout.sessionExpiryWarningTimeout ?  moment.duration(properties.sessionTimeout.sessionExpiryWarningTimeout).asMilliseconds() / 1000 : 1 * 60 * 1000);
      props.onClose();
    }
  }, [props, countdown]);

  if (!props.show) {
    return null;
  }

  return (
    <div className="backdrop">
      <div className="modal">
        <div className='modal-content'>
          <h1>Session Timeout</h1>
          <hr></hr>
          <p>Due to inactivity, your session will end soon. Click "Continue" to stay logged in.</p>
        </div>
        <div className="modal-footer">
          <button onClick={props.onLogout}>
            Logout
          </button>
          <button onClick={() => {
            props.onClose();
          }}>
            Continue ({countdown})
          </button>
        </div>
      </div>
    </div>
  );
}

export default TimeoutModal;