import React, { useState, useEffect, useContext, useReducer } from 'react';
import { UserContext } from '../Auth/Auth';
import configContext from '../ConfigContext/Config';
import { isEmpty } from 'lodash';
import _ from 'lodash';


//components
import Header from '../header/header';
import Form from '../form/form';
import WebChat from '../webchat/webchat';
import Modal from '../modal/modal';

//css
import './home.css';
import Loader from '../loader/loader';
import OtpForm from '../otp/otpForm';
import { InitiateAuthCommandOutput } from '@aws-sdk/client-cognito-identity-provider';
import { PhoneNumber } from '../../classes/PhoneNumber';
import TimeoutModal from '../modal/timeout-modal';
import { toggleCognitoFeature, toggleOTPFeature, toggleUrlTokenAuthFeature } from '../../features';

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_FORM_DATA':
      return { ...state, formData: action.payload };
    case 'SET_MESSAGE':
      return { ...state, formData: action.payload };
    default:
      return state;
  }
}

function Home() {
  const { features, styles, cognito, properties } = useContext(configContext);
  document.documentElement.style.setProperty('--main-tertiary-color', styles.mainTertiaryColor);
  document.documentElement.style.setProperty('--login-background', styles.login.background);
  document.documentElement.style.setProperty('--chat-background', styles.chatBackground);
  document.documentElement.style.setProperty('--main-sub-color', styles.mainSubColor);
  document.documentElement.style.setProperty('--header-color', styles.header.background);
  document.documentElement.style.setProperty('--header-card-color', styles.header.cardBackground);
  document.documentElement.style.setProperty('--header-card-text-color', styles.header.cardTextColor);
  document.documentElement.style.setProperty('--login-screen', styles.login.screen);
  document.documentElement.style.setProperty('--login-shape1', styles.login.shape1);
  document.documentElement.style.setProperty('--login-shape2', styles.login.shape2);
  document.documentElement.style.setProperty('--login-shape3', styles.login.shape3);
  document.documentElement.style.setProperty('--login-shape4', styles.login.shape4);

  const [state, dispatch] = useReducer(reducer, {
    formData: null,
    setMessage: ''
  });

  const { formData, message } = state;
  // const [formData, setFormData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setSubmit] = useState(false);
  const [error, setError] = useState('');
  const [otpSuccessful, setOTP] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTimeoutModal, setShowTimeoutModal] = useState(false);
  const [session, setSession] = useState<InitiateAuthCommandOutput>();
  const [username, setUsername] = useState('');
  const { authSignUp, initiateAuth, respondFirstChallenge, respondSecondChallenge, getSession, logout } = useContext(UserContext);
  const botImageModule = require(`../../assets/${properties.id}/bot.png`);
  const botImage = botImageModule.default;
  const botStyles = {
    //bot icon
    botAvatarInitials: styles.botAvatarInitials,
    accent: '#00809d',
    botAvatarBackgroundColor: "#FFFFFF",
    botAvatarImage: botImage,

    //bot messages
    // bubbleBorderColor: '#BD302C',
    // bubbleBackground: '#f2f2f2',
    bubbleBorderRadius: 20,

    //user messages
    bubbleFromUserBackground: '#ffffff',
    // bubbleFromUserBorderColor: '#BD302C',
    // bubbleFromUserBorderStyle: 'solid',
    // bubbleFromUserBorderWidth: 1,
    bubbleFromUserBorderRadius: 20,
    sendBoxButtonColorOnHover: '#BD302C',


    //adaptive cards
    cardEmphasisBackgroundColor: '#c61f48',
    cardPushButtonBackgroundColor: 'black',
    cardPushButtonTextColor: 'White',

  }

  const getUrlParams = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const data: any = {};
    for (const [key, value] of params.entries()) {
      data[key] = value;
    }
    return data;
  }


  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const urlParams: { token: string } = getUrlParams();

      await toggleUrlTokenAuthFeature(urlParams, features, onHandleFormSubmit, dispatch);
      toggleCognitoFeature(features.cognito, isEmpty, setOTP, dispatch, setSubmit, setLoading);
      toggleOTPFeature(features, getSession, setOTP, setSubmit, dispatch, setLoading);
    }
    fetchData();
  }, [])


  //form submit functions
  const onHandleFormSubmit = async (form: any) => {
    setLoading(true);
    dispatch({ type: 'SET_FORM_DATA', payload: form });
    localStorage.setItem('chat-session', JSON.stringify(form));
    //add data.contact when ready with cognito auth
    if (features.OTP) {
      authSignUp({ name: form.name, username: form.contact, type: form.type }).then(data => {
        //   console.log("first step")
        initiateAuth({ username: form.contact }).then((data: any) => {
          respondFirstChallenge(data, form.type).then((data: any) => {
            setSession(data);
            setSubmit(true);
            setLoading(false);
          }).catch(error => {
            setError(error.message);
            setLoading(false);
          })
        }).catch(error => {
          setError(error.message);
          setLoading(false);
        })
      }).catch(error => {
        console.log(error.message);
        if (error.message === "PreSignUp failed with error User exists.") {

          initiateAuth({ username: form.contact }).then((data: any) => {
            setUsername(data.ChallengeParameters?.USERNAME);
            respondFirstChallenge(data, "phone").then((data: InitiateAuthCommandOutput) => {
              setSession(data);
              setSubmit(true);
              setLoading(false);
            }).catch(error => {
              setLoading(false);
            })
          }).catch(error => {
            setLoading(false);
          })
        } else if (error.message === "Failed to fetch") {
          setLoading(false);
          setError("NET-001 Please check whether you are connected to the internet.")
        } else {
          setError(error.message);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      setOTP(true)
    }
  }

  const onHandleOTPSubmit = async (data: any) => {
    setLoading(true);

    respondSecondChallenge(session, username, formData.contact, data.otp, formData.type).then(data => {
      setSubmit(false);
      setOTP(true);
      setLoading(false);

    }).catch(error => {
      console.log("respondauth2 error:", error);
      setLoading(false);
    })
  }

  const onhandleShowModal = () => {
    setShowModal(true);
  }

  const onhandleShowTimeoutModal = () => {
    setShowTimeoutModal(true);
  }

  const onHandleLogOut = async () => {
    console.log('logging out')
    logout();
    setSubmit(false);
    setOTP(false);
    setShowModal(false);
  }

  const onCloseTimeoutModal = () => {
    setShowTimeoutModal(false);
  }

  const onRefreshSession = () => {
    setShowModal(false);
    window.location.reload();
  }

  const onErrorClose = () => {
    setError('');
  }

  return (
    <React.Fragment>
      <Loader show={loading} />
      <Header handleLogOut={onHandleLogOut} formSubmitState={otpSuccessful} onRefreshSession={onRefreshSession} />
      <div className="main-container">
        {formSubmitted ?
          <OtpForm formSubmit={onHandleOTPSubmit} />
          :
          otpSuccessful ?
            // contact: new PhoneNumber(formData.contact).getInternationalFormatWithoutPlus()
            <WebChat styleOptions={botStyles} formData={formData} onShowModal={onhandleShowModal} onShowTimeoutModal={onhandleShowTimeoutModal} startMessage={message}></WebChat>
            :
            <Form formSubmit={onHandleFormSubmit} onError={error} onErrorClose={onErrorClose} />

        }
      </div>
      <Modal show={showModal} onRefreshSession={onRefreshSession} onLogout={onHandleLogOut} />
      <TimeoutModal show={showTimeoutModal} onClose={onCloseTimeoutModal} onLogout={onHandleLogOut} />
    </React.Fragment>
  );
}

export default Home;
